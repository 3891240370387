import React from "react"
import Layout from "../../components/layout"
import Service from "../../components/service"
// seo import
import { Seo } from "../../components/Seo"

export default class Gatecrash extends Service {
  constructor(props) {
    super(props)
    this.service = new Service()
    this.service.type = "GateCrash"
    this.service.url =
      "/services/" + this.service.type.toLowerCase().replace(" ", "")
    // just added the full link here instead
    this.service.featuredImg =
      "https://i-a.cloud/cdn-cgi/imagedelivery/YZO2dKh2-nQCM3E2pZE7Kw/0ead70dd-83ca-4c32-68b8-aa1de9aee700/512"
    this.service.fiOrientation = "landscape"
    this.service.class = "ui-body__text-image"
    this.service.desc = this.description()
  }

  description() {
    return (
      <div>
        <p>
          Event-based intelligence.{" "}
          <strong className="strong-white">Leverage social media</strong> to{" "}
          <strong className="strong-white">
            discover time-sensitive trends and topics
          </strong>{" "}
          discussed by <strong className="strong-white">key employees</strong>{" "}
          at <strong className="strong-white">prospective companies</strong>.
        </p>

        <p>
          Is your competition doing an event?{" "}
          <strong className="strong-white">Gatecrash the party</strong> and let
          their customers know why they should be with you instead.
        </p>
      </div>
    )
  }

  render() {
    if (typeof document !== `undefined`) {
      if (window.location.pathname.includes(this.service.url)) {
        return (
          <Layout>
            <div className="ui-body-container products">
              <h1 className="body-title product-title">
                {"IA ".concat("> ").concat(this.service.type)}
              </h1>

              <div className="spacing-divider--extra-small"></div>

              <img
                src="https://i-a.cloud/cdn-cgi/imagedelivery/YZO2dKh2-nQCM3E2pZE7Kw/0ead70dd-83ca-4c32-68b8-aa1de9aee700/512"
                alt="Gatecrash logo"
                placeholder="none"
                className="products-img-full"
                loading="lazy"
              ></img>

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Analyse Trends</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    <strong className="strong-white">
                      Gatecrash an event of your choosing
                    </strong>
                    , competition and beyond. Just provide us the where/ when
                    and we’ll generate a{" "}
                    <strong className="strong-white">
                      detailed report of the top trends and companies interested
                    </strong>
                    .
                  </p>
                </div>
                <div className="ui-body__text-image--right ui-body__text-image--right-text">
                  <h1 className="body-title">Key Interactions</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    This is great for{" "}
                    <strong className="strong-white">
                      scoping out your competition’s clients
                    </strong>{" "}
                    so you can contact them with{" "}
                    <strong className="strong-white">tailored messaging</strong>{" "}
                    based on what they interact with at the event!
                  </p>
                </div>
              </div>

              <div className="spacing-divider--small"></div>

              <h1 className="body-title scroll-fade">
                We provide data-driven reports on companies, people and events
                that redefine prospecting and account-based marketing.
              </h1>

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">GateCrash Graphs</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    We also supply a complete list of all the employees and the
                    raw intelligence on this graph so you can follow up with
                    them on linkedin.
                  </p>
                  <p>
                    Need help reading graphs? Our{" "}
                    <a href="https://wiki.i-a.cloud/en/graphs">Wiki</a> has you
                    covered!
                  </p>
                </div>
                <div className="ui-body__text-image--right">
                  <img
                    src="https://imagedelivery.net/YZO2dKh2-nQCM3E2pZE7Kw/f2422f02-5776-4f9e-e49a-64ecafd6a900/512"
                    alt="Example gatecrash graph"
                    placeholder="none"
                    objectFit="contain"
                    className=""
                    loading="lazy"
                  ></img>
                </div>
              </div>
            </div>
          </Layout>
        )
      }
    }
  }
}

export const Head = () => <Seo />
